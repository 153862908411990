import logo from './assets/logo';
import './App.css';
import styled from 'styled-components';
import { useState } from 'react';

const StyledApp = styled.div`
  text-align: center;
  background-color: white;
`;

const BreathingLogo = styled.img<{ spin?: string, bounce?: string }>`
@media (prefers-reduced-motion: no-preference) {
  animation: App-logo-spin infinite ${props => props.spin || "5.5s"} linear, App-logo-bounce infinite ${props => props.bounce || "5.5s"} ease-in-out;
}
`;

function App() {
  const [spin, setSpin] = useState("7.5s")
  const [bounce, setBounce] = useState("7.5s")


  
  return (
    <StyledApp>
      <header className="App-header" onClick={() => {setSpin(`${Math.floor(Math.random() * 10) + 1}s`); setBounce(`${Math.floor(Math.random() * 10) + 1}s`) }}>
        {/* <BreathingLogo src={logo} className="App-logo" alt="logo" spin={spin}  /> */}
        <BreathingLogo src={logo} className="App-logo" alt="logo" spin={spin} bounce={bounce} />
      </header>
    </StyledApp>
  );
}

export default App;
